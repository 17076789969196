import { BrowserRouter, Route, Routes } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import Home from "./components/Home";
import Nav from "./components/Nav";
import Footer from "./components/Footer";
import Surah from "./components/Surah";
import Parah from "./components/Parah";
import Manzil from "./components/Manzil";
import Ruku from "./components/Ruku";
import Sajda from "./components/Sajda";
import FetchSurah from "./components/FetchSurah";
import FetchParah from "./components/FetchParah";
import FetchManzil from "./components/FetchManzil";

function App() {
  const queryClient = new QueryClient();
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <Nav />
        <div className="poppins min-h-[100vh]">
          <Routes>
            <Route exact path="*" element={<Home />} />
            <Route exact path="/surah" element={<Surah />} />
            <Route exact path="/surah/:id" element={<FetchSurah />} />
            <Route exact path="/parah" element={<Parah />} />
            <Route exact path="/parah/:id" element={<FetchParah />} />
            <Route exact path="/manzil" element={<Manzil />} />
            <Route exact path="/manzil/:id" element={<FetchManzil />} />
            <Route exact path="/ruku" element={<Ruku />} />
            <Route exact path="/ruku/:id" element={<Ruku />} />
            <Route exact path="/sajda" element={<Sajda />} />
            <Route exact path="/sajda/:id" element={<Sajda />} />
          </Routes>
        </div>
        <Footer />
      </BrowserRouter>
    </QueryClientProvider>
  );
}

export default App;
