import React from "react";
import { useQuery } from "@tanstack/react-query";
import { myInstance } from "../apis/intances";
import Loading from "./Loading";
import Error from "./Error";
import { Link } from "react-router-dom";

function Surah() {
  const fetchSurahs = async () => {
    const response = await myInstance.get("/surah"); // Use the Axios instance
    return response.data;
  };

  const { isLoading, error, data } = useQuery({
    queryKey: ["surah"],
    queryFn: fetchSurahs,
  });

  return (
    <div className="mx-4">
      <div className="text-xl text-center px-4 py-10 "><span className="border-b-2 rounded-lg border-black w-fit p-2">Read any Surah you want</span></div>
      <div>{isLoading ? <Loading /> : ""}</div>
      <div>{error ? <Error message={error.message} /> : ""}</div>
      <div className="py-10">
        {data ? (
          <div className="flex flex-wrap flex-col">
            {data.data.map((ele, ind) => {
              return (
                <Link key={ind} to={`/surah/${ind+1}`} className="flex gap-10 p-4 justify-between md:justify-evenly items-center border-b-2">
                  <div>{ind+1}{')'}. {ele.englishName}</div>
                  <div className="arabic text-xl">{ele.name}</div>
                  <div className="hidden md:block">{ele.englishNameTranslation}</div>
                </Link>
              );
            })}
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default Surah;
