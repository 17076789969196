import React, { useEffect } from "react";
import RandomAyat from "./RandomAyat";
import { Link } from "react-router-dom";
import { useState } from "react";

function Home() {
  const [Time, setTime] = useState("");

  const time = () => {
    const fullDate = new Date();
    const timeWithoutSeconds = fullDate.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true, // Optional: Use 12-hour clock format if desired
    });
    setTime(timeWithoutSeconds);
  };

  setInterval(() => {
    time();
  }, 60000);

  useEffect(() => {
    time();
  }, []);

  return (
    <div className="poppins">
      <div className="arabic text-black text-2xl py-10 text-center">
        بِسْمِ ٱللّٰهِ الرَّحْمٰنِ الرَّحِيْمِ
      </div>
      <div className="p-10 flex items-center justify-center gap-1">
        <span className="bg-gray-200 p-4 text-3xl rounded-md">{Time}</span>
      </div>
      <RandomAyat />
      {/* <div className="p-10 flex items-center justify-center gap-1">
        <span className="bg-gray-200 p-2 text-3xl rounded-md">
          {Time[0]}
          {Time[1]}
        </span>
        <span className="">{Time[2]}</span>
        <span className="bg-gray-200 p-2 text-3xl rounded-md">
          {Time[3]}
          {Time[4]}
        </span>
        <span className=""></span>
        <span className="bg-gray-200 p-2 text-3xl rounded-md uppercase">
          {Time[9]}
          {Time[10]}
        </span>
      </div> */}
      <div className="flex flex-col items-center justify-center flex-wrap py-10">
        <div className="text-center text-xl border-b-2 border-black w-fit p-4 rounded-lg">
          Read anyone of the following
        </div>
        <ul className="py-10 flex flex-wrap gap-10 items-center justify-center ">
          <Link
            className="bg-[#283618] text-xl p-2 rounded-md text-white w-28 text-center hover:scale-105 hover:drop-shadow-2xl transition-all"
            to="/surah"
          >
            Surahs
          </Link>
          <Link
            className="bg-[#283618] text-xl p-2 rounded-md text-white w-28 text-center hover:scale-105 hover:drop-shadow-2xl transition-all"
            to="/parah"
          >
            Parahs
          </Link>
          {/* <Link
            className="bg-[#283618] text-xl p-2 rounded-md text-white w-28 text-center hover:scale-105 hover:drop-shadow-2xl transition-all"
            to="/manzil"
          >
            Manzil
          </Link>
          <Link
            className="bg-[#283618] text-xl p-2 rounded-md text-white w-28 text-center hover:scale-105 hover:drop-shadow-2xl transition-all"
            to="/ruku"
          >
            Ruku
          </Link> */}
          <Link
            className="bg-[#283618] text-xl p-2 rounded-md text-white w-28 text-center hover:scale-105 hover:drop-shadow-2xl transition-all"
            to="/sajda"
          >
            Sajdas
          </Link>
        </ul>
      </div>
    </div>
  );
}

export default Home;
