import React from "react";
import { useQuery } from "@tanstack/react-query";
import { myInstance } from "../apis/intances";
import Loading from "./Loading";
import Error from "./Error";
import { Link } from "react-router-dom";
import { useState } from "react";

function Parah() {
  const [Parahs, setParahs] = useState([
    {
      name: "آلم",
    },
    {
      name: "سَيَقُولُ",
    },
    {
      name: "الرُّسُلُ تِلْكَ",
    },
    {
      name: "تَنَالُوا لَنْ",
    },
    {
      name: "وَالْمُحْصَنَاتُ",
    },
    {
      name: "اللَّهُ يُحِبُّ لَا",
    },
    {
      name: "سَمِعُوا وَإِذَا",
    },
    {
      name: "أَنَّنَا وَلَوْ",
    },
    {
      name: "الْمَلَأُ قَالَ",
    },
    {
      name: "وَاعْلَمُوا",
    },
    {
      name: "يَعْتَذِرُونَ",
    },
    {
      name: "دَابَّةٍ مِنْ وَمَا",
    },
    {
      name: "بَرِّئُوَمَا",
    },
    {
      name: "رُبَمَا",
    },
    {
      name: "الَّذِي سُبْحَانَ",
    },
    {
      name: "أَلَمْ قَالَ",
    },
    {
      name: "اقْتَرَبَ",
    },
    {
      name: "أَفْلَحَ قَدْ",
    },
    {
      name: "الَّذِينَ وَقَالَ",
    },
    {
      name: "خَلَقَ أَمَّنْ",
    },
    {
      name: "أُوحِيَ مَا اتْلُ",
    },
    {
      name: "يَقْنُتْ وَمَنْ",
    },
    {
      name: "لِيَ وَمَا",
    },
    {
      name: "أَظْلَمُ فَمَنْ",
    },
    {
      name: "يُرَدُّ إِلَيْهِ",
    },
    {
      name: "حم",
    },
    {
      name: "خَطْبُكُمْ فَمَا قَال",
    },
    {
      name: "اللَّهُ سَمِعَ قَدْ",
    },
    {
      name: "الَّذِي تَبَارَكَ",
    },
    {
      name: "يَتَسَاءَلُونَ عَمَّ",
    },
  ]);

  return (
    <div className="mx-4">
      <div className="text-xl text-center px-4 py-10 ">
        <span className="border-b-2 rounded-lg border-black w-fit p-2">
          Read any Parah you want
        </span>
      </div>
      <div>
        {
          Parahs.map((ele, ind) => {
            return <Link to={`/parah/${ind + 1}`} key={ind} className="flex flex-wrap justify-between items-center border-b-2 p-2">
              <div className="">Parah ({ind + 1})</div>
              <div className="arabic text-xl">{ele.name}</div>
            </Link>
          })
        }
      </div>
    </div>
  );
}

export default Parah;
