import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { myInstance } from "../apis/intances";
import { useQuery } from "@tanstack/react-query";
import Loading from "./Loading";
import Error from "./Error";

function FetchParah() {

  const [Parahs, setParahs] = useState([
    {
      name: "آلم",
    },
    {
      name: "سَيَقُولُ",
    },
    {
      name: "الرُّسُلُ تِلْكَ",
    },
    {
      name: "تَنَالُوا لَنْ",
    },
    {
      name: "وَالْمُحْصَنَاتُ",
    },
    {
      name: "اللَّهُ يُحِبُّ لَا",
    },
    {
      name: "سَمِعُوا وَإِذَا",
    },
    {
      name: "أَنَّنَا وَلَوْ",
    },
    {
      name: "الْمَلَأُ قَالَ",
    },
    {
      name: "وَاعْلَمُوا",
    },
    {
      name: "يَعْتَذِرُونَ",
    },
    {
      name: "دَابَّةٍ مِنْ وَمَا",
    },
    {
      name: "بَرِّئُوَمَا",
    },
    {
      name: "رُبَمَا",
    },
    {
      name: "الَّذِي سُبْحَانَ",
    },
    {
      name: "أَلَمْ قَالَ",
    },
    {
      name: "اقْتَرَبَ",
    },
    {
      name: "أَفْلَحَ قَدْ",
    },
    {
      name: "الَّذِينَ وَقَالَ",
    },
    {
      name: "خَلَقَ أَمَّنْ",
    },
    {
      name: "أُوحِيَ مَا اتْلُ",
    },
    {
      name: "يَقْنُتْ وَمَنْ",
    },
    {
      name: "لِيَ وَمَا",
    },
    {
      name: "أَظْلَمُ فَمَنْ",
    },
    {
      name: "يُرَدُّ إِلَيْهِ",
    },
    {
      name: "حم",
    },
    {
      name: "خَطْبُكُمْ فَمَا قَال",
    },
    {
      name: "اللَّهُ سَمِعَ قَدْ",
    },
    {
      name: "الَّذِي تَبَارَكَ",
    },
    {
      name: "يَتَسَاءَلُونَ عَمَّ",
    },
  ]);

  const { id } = useParams();

  const fetchParah = async () => {
    const response1 = await myInstance.get(`/juz/${id}/quran-uthmani`); // Use the Axios instance
    const response2 = await myInstance.get(`/juz/${id}/en.asad`); // Use the Axios instance
    const data = {
      r1: response1.data,
      r2: response2.data,
    };
    return data;
  };

  const { isLoading, error, data } = useQuery({
    queryKey: [`fetchParah-${id}`,],
    queryFn: fetchParah,
  });

  return (
    <div>
      <div>{isLoading ? <Loading /> : ""}</div>
      <div>{error ? <Error message={error.message} /> : ""}</div>
      <div>
        {data ? (
          <div className="">
            <div className="flex flex-col flex-wrap justify-center items-center gap-4 py-10">
              <div className="">({data.r1.data.number})</div>
              <div className="text-4xl arabic border-b-2 border-black rounded-lg p-6">
                {Parahs[data.r1.data.number].name}
              </div>
            </div>
            <div className="p-6">
              {data.r1.data.ayahs.map((ele, ind) => {
                return (
                  <div key={ind} className="flex gap-4 flex-col p-4 border-b-2">
                    <div className="arabic text-right text-xl">
                      {ele.text} ({ele.numberInSurah})
                    </div>
                    <div>{data.r2.data.ayahs[ind].text}</div>
                  </div>
                );
              })}
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default FetchParah;
