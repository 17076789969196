import React from "react";
import { useParams } from "react-router-dom";
import { myInstance } from "../apis/intances";
import { useQuery } from "@tanstack/react-query";
import Loading from "./Loading";
import Error from "./Error";

function FetchSurah() {
  const { id } = useParams();

  const fetchSurah = async () => {
    const response1 = await myInstance.get(`/surah/${id}`); // Use the Axios instance
    const response2 = await myInstance.get(`/surah/${id}/en.asad`); // Use the Axios instance
    const data = {
      r1: response1.data,
      r2: response2.data,
    };
    return data;
  };

  const { isLoading, error, data } = useQuery({
    queryKey: [`fetchSurah-${id}`],
    queryFn: fetchSurah,
  });

  return (
    <div>
      <div>{isLoading ? <Loading /> : ""}</div>
      <div>{error ? <Error message={error.message} /> : ""}</div>
      <div>
        {data ? (
          <div className="">
            <div className="flex flex-col flex-wrap justify-center items-center gap-4 py-10">
              <div className="">({data.r1.data.number})</div>
              <div className="flex justify-center items-center gap-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="currentColor"
                  className="bi bi-book"
                  viewBox="0 0 16 16"
                >
                  <path d="M1 2.828c.885-.37 2.154-.769 3.388-.893 1.33-.134 2.458.063 3.112.752v9.746c-.935-.53-2.12-.603-3.213-.493-1.18.12-2.37.461-3.287.811zm7.5-.141c.654-.689 1.782-.886 3.112-.752 1.234.124 2.503.523 3.388.893v9.923c-.918-.35-2.107-.692-3.287-.81-1.094-.111-2.278-.039-3.213.492zM8 1.783C7.015.936 5.587.81 4.287.94c-1.514.153-3.042.672-3.994 1.105A.5.5 0 0 0 0 2.5v11a.5.5 0 0 0 .707.455c.882-.4 2.303-.881 3.68-1.02 1.409-.142 2.59.087 3.223.877a.5.5 0 0 0 .78 0c.633-.79 1.814-1.019 3.222-.877 1.378.139 2.8.62 3.681 1.02A.5.5 0 0 0 16 13.5v-11a.5.5 0 0 0-.293-.455c-.952-.433-2.48-.952-3.994-1.105C10.413.809 8.985.936 8 1.783" />
                </svg>
                {data.r1.data.revelationType}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="currentColor"
                  className="bi bi-book"
                  viewBox="0 0 16 16"
                >
                  <path d="M1 2.828c.885-.37 2.154-.769 3.388-.893 1.33-.134 2.458.063 3.112.752v9.746c-.935-.53-2.12-.603-3.213-.493-1.18.12-2.37.461-3.287.811zm7.5-.141c.654-.689 1.782-.886 3.112-.752 1.234.124 2.503.523 3.388.893v9.923c-.918-.35-2.107-.692-3.287-.81-1.094-.111-2.278-.039-3.213.492zM8 1.783C7.015.936 5.587.81 4.287.94c-1.514.153-3.042.672-3.994 1.105A.5.5 0 0 0 0 2.5v11a.5.5 0 0 0 .707.455c.882-.4 2.303-.881 3.68-1.02 1.409-.142 2.59.087 3.223.877a.5.5 0 0 0 .78 0c.633-.79 1.814-1.019 3.222-.877 1.378.139 2.8.62 3.681 1.02A.5.5 0 0 0 16 13.5v-11a.5.5 0 0 0-.293-.455c-.952-.433-2.48-.952-3.994-1.105C10.413.809 8.985.936 8 1.783" />
                </svg>
              </div>
              <div className="text-4xl arabic border-b-2 border-black rounded-lg p-6">{data.r1.data.name}</div>
              <div></div>
              <div>
                "{data.r1.data.englishName}" means "
                {data.r1.data.englishNameTranslation}"
              </div>
              <div className="flex gap-10">
                <span>Ayats ({data.r1.data.numberOfAyahs})</span>
                <span>
                  Ruku ({data.r1.data.ayahs[0].ruku} -{" "}
                  {data.r1.data.ayahs[data.r1.data.numberOfAyahs - 1].ruku})
                </span>
              </div>
            </div>
            <div className="p-6">
              {data.r1.data.ayahs.map((ele, ind) => {
                return (
                  <div key={ind} className="flex gap-4 flex-col p-4 border-b-2">
                    <div className="arabic text-right text-xl">
                      {ele.text} ({ele.numberInSurah})
                    </div>
                    <div>
                        {data.r2.data.ayahs[ind].text}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default FetchSurah;
