import React from "react";
import { useQuery } from "@tanstack/react-query";
import { myInstance } from "../apis/intances";
import Loading from "./Loading";
import Error from "./Error";

function RandomAyat() {
  const fetchRandomAyat = async () => {
    // Generate a random number between 0 and 1 (exclusive)
    const randomDecimal = Math.random();
    // Multiply by the desired range (6236) and add 1 (to include 1 in the range)
    const randomNumber = Math.floor(randomDecimal * 6236) + 1;
    const response1 = await myInstance.get(`/ayah/${randomNumber}`); // Use the Axios instance
    const response2 = await myInstance.get(`/ayah/${randomNumber}/en.asad`); // Use the Axios instance
    const data = {
      ayat: response1.data,
      trans: response2.data,
    };
    return data;
  };

  const { isLoading, error, data } = useQuery({
    queryKey: ["fetchRandomAyat"],
    queryFn: fetchRandomAyat,
  });

  return (
    <div className="mx-4">
      <div>{isLoading ? <Loading /> : ""}</div>
      <div>{error ? <Error message={error.message} /> : ""}</div>
      <div className="bg-[#FEFAE0] rounded-xl drop-shadow-2xl">
        {data ? (
          <div className="text-center p-6 min-h-[100vh] flex justify-center items-center gap-12 flex-wrap flex-col">
            <div className="arabic text-xl border-b-2 border-black py-4 rounded-xl">{data.ayat.data.surah.name}</div>
            <div className="arabic text-3xl text-center">{data.ayat.data.text}</div>
            <div className="text-xl">"{data.trans.data.text}"</div>
            <div className="border-t-2 border-black py-4 rounded-xl">Ayat ({data.ayat.data.number})</div>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default RandomAyat;
