import React from "react";
import "../styles/style.css";
import { Link } from "react-router-dom";

function Nav() {
  return (
    <div className="bg-[#FEFAE0] flex flex-col gap-2 justify-center items-center drop-shadow-2xl rounded-b-2xl">
      <div className="py-4 px-8 flex flex-wrap gap-5 w-full justify-center items-center">
        <Link to="/" className="arabic text-black text-2xl">
          الْقُرْآن الْكَرِيْم
        </Link>
        {/* <ul className="flex flex-wrap gap-6 justify-center">
          <Link to="/surah">Surahs</Link>
          <Link to="/parah">Parahs</Link>
          <Link to="/manzil">Manzil</Link>
          <Link to="/ruku">Ruku</Link>
          <Link to="/sajda">Sajda</Link>
          <Link to="/contact">Contact</Link>
        </ul> */}
      </div>
    </div>
  );
}

export default Nav;
