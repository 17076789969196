import React from "react";
import { useQuery } from "@tanstack/react-query";
import { myInstance } from "../apis/intances";
import Loading from "./Loading";
import Error from "./Error";

function Sajda() {
  const fetchSurahs = async () => {
    const response1 = await myInstance.get("/sajda/quran-uthmani"); // Use the Axios instance
    const response2 = await myInstance.get("/sajda/en.asad"); // Use the Axios instance
    const data = {
      r1: response1.data,
      r2: response2.data
    }
    console.log(data);
    return data;
  };

  const { isLoading, error, data } = useQuery({
    queryKey: ["surah"],
    queryFn: fetchSurahs,
  });

  return (
    <div className="mx-4">
      <div className="text-xl text-center px-4 py-10 "><span className="border-b-2 rounded-lg border-black w-fit p-2">Read and do any Sajda you want</span></div>
      <div>{isLoading ? <Loading /> : ""}</div>
      <div>{error ? <Error message={error.message} /> : ""}</div>
      <div className="py-10">
        {data ? (
          <div className="flex flex-wrap flex-col">
            {data.r1.data.ayahs.map((ele, ind) => {
              return (
                <div key={ind} className="flex flex-col gap-4 flex-wrap p-6 border-b-2 items-center justify-center">
                  <div>({ind+1})</div>
                  <div className="arabic border-b-2 border-black rounded-lg p-2">{ele.surah.name}</div>
                  <div className="arabic text-xl text-center px-2">{ele.text}</div>
                  <div className="text-center">{data.r2.data.ayahs[ind].text}</div>
                  <ul className="w-fit text-xs flex flex-wrap gap-6 justify-center border-t-2 border-black rounded-lg px-4 py-2">
                    <li className="">Manzil ({ele.manzil})</li>
                    <li className="">Parah ({ele.juz})</li>
                    <li className="">Ruku ({ele.ruku})</li>
                    <li className="">Surah ({ele.surah.englishName})</li>
                  </ul>
                </div>
              );
            })}
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default Sajda;