import React from 'react'

function Loading() {
  return (
    <div className='min-h- min-h-[100vh] flex justify-center items-center flex-col gap-10'>
        <div className="w-10 h-10 border-t-4 rounded-full border-[#283618] animate-spin"></div>
        <div className='text-sm'>Please wait a while!</div>
    </div>
  )
}

export default Loading