import React from 'react'
import { Link } from 'react-router-dom'

function Manzil() {
  return (
    <div className='mx-4'>
      <div className="text-xl text-center px-4 py-10 "><span className="border-b-2 rounded-lg border-black w-fit p-2">Read and Manzil you want</span></div>
      <div className='py-10 flex items-center flex-col'>
        <Link to='/manzil/:1' className='p-4 border-b-2 w-full'>Al Quran Manzil 1</Link>
        <Link to='/manzil/:2' className='p-4 border-b-2 w-full'>Al Quran Manzil 2</Link>
        <Link to='/manzil/:3' className='p-4 border-b-2 w-full'>Al Quran Manzil 3</Link>
        <Link to='/manzil/:4' className='p-4 border-b-2 w-full'>Al Quran Manzil 4</Link>
        <Link to='/manzil/:5' className='p-4 border-b-2 w-full'>Al Quran Manzil 5</Link>
        <Link to='/manzil/:6' className='p-4 border-b-2 w-full'>Al Quran Manzil 6</Link>
        <Link to='/manzil/:7' className='p-4 border-b-2 w-full'>Al Quran Manzil 7</Link>
      </div>
    </div>
  )
}

export default Manzil